import { useNewBlock } from './useNewBlock';
import { useMainDocumentSwap } from './useMainBlockSwap';

export function useNewNoteCommand(appId: string) {
  const createNote = useNewBlock();
  const swapMainDocument = useMainDocumentSwap();
  //   console.log('set up useNewNote');
  document.getElementById(appId)?.addEventListener('keydown', e => {
    // Does this work across browsers?
    // console.log('key event', e);
    if (e.metaKey && e.code == 'KeyK') {
      const id = createNote();
      swapMainDocument(id);
    }
  });
}
