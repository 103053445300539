import React from 'react';

export const Well: React.FC<{ type?: 'error' | 'success' | 'neutral' }> = ({
  children,
  type = 'neutral',
}) => {
  return (
    <div
      className={`bg-background w-full p-200 rounded-small font-base border-medium ${
        type == 'error' ? 'border-red' : type == 'success' ? 'border-green' : ''
      } flex flex-col items-stretch`}>
      {children}
    </div>
  );
};
