import { block_id } from '../api/protocol';
import { useMainState } from './useMain';
import { useNewContextDocument } from './useNewContextBlock';

/// A hook to swap the main document w/ a new one, and move the current main document into the context

export const useMainDocumentSwap = () => {
  const newContextDocument = useNewContextDocument();
  const [main_note_id, set_main_note_id] = useMainState();
  return (swapWith: block_id) => {
    newContextDocument(main_note_id, { side: 'left', el: 'last' });
    set_main_note_id(swapWith);
  };
};
