import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

type Config = Parameters<typeof firebase.initializeApp>[0];

class Fuego {
  public db: ReturnType<firebase.app.App['firestore']>;
  public auth: ReturnType<typeof firebase.auth>;
  public functions: ReturnType<typeof firebase.functions>;
  public storage: ReturnType<typeof firebase.storage>;
  public storageRef: firebase.storage.Reference;

  constructor(config: Config) {
    firebase.initializeApp(config);
    firebase.analytics();
    this.db = firebase.firestore();
    this.auth = firebase.auth();
    this.functions = firebase.functions();
    this.storage = firebase.storage();

    // Setting up to use with emulator
    if (location.hostname === 'localhost') {
      this.db.useEmulator('localhost', 8080);
      this.auth.useEmulator('http://localhost:9099');
      this.storage.useEmulator('localhost', 9199);
    }
    this.storageRef = this.storage.ref();
  }
}

export const fuego = new Fuego(firebaseConfig);
