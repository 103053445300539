import { useContextState } from './useContext';
import { useBlock } from './useBlock';
import { useMainState } from './useMain';

/// Returns a function that deletes the main and the associated document
/// TODO: use history to get the last note we were working on, and make that the main instead
/// TODO: if empty, open up a empty note?
export function useDeleteMain() {
  const [mainId, setMainId] = useMainState();
  const [_, setContext] = useContextState();
  const { deleteDocument } = useBlock(mainId);
  return () => {
    let lastUsed = undefined;
    setContext(context => {
      const _context_left = [...context.left];
      lastUsed = _context_left.shift();
      const _context = { ...context, left: _context_left };
      // Remove first element from left

      return _context;
    });
    if (lastUsed) {
      setMainId(lastUsed);
    }
    // Will this actually delete the main id that we expect?
    // Or does it get overrwritten from the above call?
    deleteDocument();
  };
}
