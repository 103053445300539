import React from 'react';
import { fuego } from '../services/firestore';
import { Button } from '../components/base/Button';
import { TextField } from '../components/base/Textfield';
import { Form } from '../components/base/Form';
import { Well } from '../components/base/Well';
import { FormProps } from '../components/base/Form';

const auth = fuego.auth;
const db = fuego.db;

interface NotesDict {
  note_ref: string[];
}

// Notes dictwill contain the dir of the note in firebase as the key and it's value will be the location of the matched string in the file
export const Search: React.FC<FormProps> = props => {
  const { isDisabled } = props;
  // const { data } = useBlocks();
  const [notes_info, set_notes_info] = React.useState<{
    notes_dict: NotesDict | undefined;
    message?: string;
    search_str: string;
  }>({ notes_dict: undefined, search_str: '' });
  return (
    <Form>
      <TextField
        label="Name"
        onChange={(input: string) => {
          set_notes_info({ ...notes_info, search_str: input });
        }}
        isDisabled={isDisabled}
      />
      <Button
        variant="primary"
        onPress={() => {
          const user = auth.currentUser;
          if (user) {
            // console.log('user id', user.uid);
            db.collection('notes')
              .where('user', '==', user.uid)
              .where('title', '>=', notes_info.search_str)
              .where('title', '<=', notes_info.search_str + '\uf8ff')
              .get()
              .then(function (docs) {
                if (docs.empty) {
                  set_notes_info({
                    ...notes_info,
                    notes_dict: {
                      ...notes_info.notes_dict,
                      note_ref: [],
                    },
                    message: 'No documents for this user and title',
                  });
                } else {
                  const notes_l: string[] = [];
                  docs.forEach(doc => {
                    // console.log(doc.id);
                    notes_l.push(doc.id);
                  });
                  set_notes_info({
                    ...notes_info,
                    notes_dict: {
                      ...notes_info.notes_dict,
                      note_ref: notes_l,
                    },
                    message: 'Found documents for this user and title',
                  });
                }
              });
          } else {
            set_notes_info({ ...notes_info, message: 'No user logged in' });
          }
        }}
        isDisabled={isDisabled}>
        {' '}
        Search
      </Button>
      <Well>{notes_info.message}</Well>

      {/* {notes_info.notes_dict && (
          <ListBox>
            {notes_info.notes_dict.note_ref.map(item => (
              <Item key={item}>{item}</Item>
            ))}
          </ListBox>
        )} */}
    </Form>
  );
};
