export interface Whim {
  version: '0.0.1';
  state: State;
  documents: Documents;
}

export type Documents = Record<block_id, WhimBlock>;

export type State = {
  main: Main;
  context: Context;
};

export interface Context {
  left: block_id[];
  right: block_id[];
}

export type Main = block_id;

export const colors = [
  'celery',
  'chartreuse',
  'magenta',
  'fuchsia',
  'indigo',
  'seafoam',
  'orange',
] as const;

export type Metadata = {
  id: block_id;
  public: boolean;
  owner: user_id;
  viewers: user_id[];
  color: typeof colors[number];
};

/** WhimBlock contains the semantic information for a block: its content, basically */
export type WhimBlock =
  | WhimNoteBlock
  | WhimLoginBlock
  | WhimSearchBlock
  | WhimRegisterBlock
  | WhimMediaBlock;

/**
 * StoredWhimBlock is different from WhimBlock in that it stores metadata
 * This is the data storage format in firebase, so its what the firestore interface uses.
 */
export type StoredWhimBlock = Metadata & WhimBlock;

export type WhimBlockType = 'doc' | 'login' | 'register' | 'search' | 'media';

/**
 * For some kinds of blocks (notes, images), it gets created on the server
 * For other user blocks (search, shelf), it gets created and maintained on the client (locally)
 */
export type block_id = string;

export type user_id = string;

/**
 * Index refers to a local indexing system for the block
 * So you use the Index to get the block_id for a block, which you use to get the contents of the block itself
 */
export type Index = Readonly<{
  side: 'left' | 'right';
  el: number | 'last';
}>;

export interface WhimLoginBlock {
  type: 'login';
  title: 'Login';
}

export interface WhimSearchBlock {
  type: 'search';
  title: 'Search';
}

export interface WhimMediaBlock {
  type: 'media';
  title: string;
  src: string;
  alt?: string;
}

export interface WhimRegisterBlock {
  type: 'register';
  title: 'Register';
}

export interface WhimNoteBlock {
  type: 'note';
  tags: string[];
  content: WhimNode[];
  title: string;
}

export type WhimNode =
  | WhimParagraph
  | WhimDivider
  | WhimHeading
  // | WhimImage
  | WhimCodeBlock;

export interface WhimText {
  type: 'text';
  text: string;
}

export interface WhimParagraph {
  type: 'paragraph';
  content: [WhimText];
}

export interface WhimDivider {
  type: 'horizontalRule';
}

export interface WhimHeading {
  type: 'heading';
  content: [WhimText];
}

export interface WhimCodeBlock {
  type: 'codeBlock';
  attrs: { language: number };
  content: [WhimText];
}
// export interface WhimLatexElement extends Element {
//   type: 'latex';
//   children: Text[];
// }
