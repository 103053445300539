import produce from 'immer';
import { block_id, Index } from '../api/protocol';
import { useContextState } from './useContext';

/// Put a document into the context
/// Returns a function that takes the id for a document, the index it should move into and places it
export function useNewContextDocument() {
  const [_, setContext] = useContextState();
  return (id: block_id, index: Index) => {
    const side = index.side;
    setContext(base =>
      produce(base, draft => {
        draft[side][index.el == 'last' ? draft[side].length - 1 : index.el] =
          id;
      })
    );
  };
}
