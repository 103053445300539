import { AllowType, Document } from '@nandorojo/swr-firestore';
import { FieldValue } from '@firebase/firestore-types';
import { WhimBlock } from '../api/protocol';
import { EditorContent, useEditor } from '@tiptap/react';
import { editor_extensions } from './Editor';
import React from 'react';

export const NoteMain: React.FC<{
  data?: Document<WhimBlock>;
  update: (
    data: Partial<AllowType<WhimBlock, FieldValue>>
  ) => Promise<void> | null;
  main_id: string;
  loading: boolean;
}> = ({ data, update, main_id, loading }) => {
  const editor = useEditor(
    {
      extensions: editor_extensions,
      editable: true,
      content: data,
      onUpdate({ editor }) {
        update(editor.state.doc.toJSON());
      },
    },
    [main_id, loading]
  );

  return <EditorContent editor={editor} />;
};


export const NoteContext: React.FC<{
  block?: Document<WhimBlock>;
  block_id: string;
}> = ({ block, block_id }) => {
  const editor = useEditor(
    {
      extensions: editor_extensions,
      editable: false,
      content: block,
    },
    [block_id]
  );

  return <EditorContent editor={editor} />;
};
