import { Button } from '../../components/base/Button';
import { TextField } from '../../components/base/Textfield';
import { Form } from '../../components/base/Form';
import { Well } from '../../components/base/Well';
import React from 'react';

import { fuego } from '../../services/firestore';
const auth = fuego.auth;
import { FormProps } from '../../components/base/Form';

/**
 * Register component for a user to register as a new user
 */
export const Register: React.FC<FormProps> = props => {
  const { isDisabled } = props;
  const [text, setText] = React.useState({
    email: 'place_email',
    password: 'place_password',
    confirm_password: 'confirm_password',
    message: 'not logged in',
  });

  return (
    <Form {...props}>
      <TextField
        label="Email"
        type="email"
        onChange={(value: string) => setText({ ...text, email: value })}
        isDisabled={isDisabled}
      />
      <TextField
        label="Password"
        type="password"
        onChange={(value: string) => setText({ ...text, password: value })}
        isDisabled={isDisabled}
      />
      <TextField
        label="Confirm Password"
        type="password"
        onChange={(value: string) =>
          setText({ ...text, confirm_password: value })
        }
        isDisabled={isDisabled}
      />
      <Button
        variant="primary"
        onPress={() => {
          const passwords_match = text.password == text.confirm_password;
          const regex_match =
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(
              text.password
            );
          // console.log('passwords_match', passwords_match);
          // console.log('regex_match', regex_match);
          if (passwords_match && regex_match) {
            auth
              .createUserWithEmailAndPassword(text.email, text.password)
              .then(userCredential => {
                // Signed in
                const user = userCredential.user;
                setText({ ...text, message: `registered user ${user}` });
                auth.signInWithEmailAndPassword(text.email, text.password);
                // ...
              })
              .catch(error => {
                setText({ ...text, message: error.message });
              });
          } else {
            // If passwords match then issue is with regex pattern
            if (passwords_match) {
              setText({
                ...text,
                message:
                  'password needs: 1 lowercase letter, 1 uppercase letter, 1 special character, 1 number, and must be >=8 characters',
              });
            } else {
              setText({
                ...text,
                message: "password and confirm password don't match",
              });
            }
          }
        }}
        isDisabled={isDisabled}>
        Register
      </Button>

      <Button
        variant="primary"
        onPress={() => {
          if (auth.currentUser?.email) {
            setText({
              ...text,
              message: 'current user is ' + auth.currentUser?.email,
            });
          } else {
            setText({ ...text, message: 'there is no current user' });
          }
        }}
        isDisabled={isDisabled}>
        Get Current User
      </Button>
      <Well>{text.message}</Well>
    </Form>
  );
};
