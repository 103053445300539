import { useCollection, useDocument } from '@nandorojo/swr-firestore';
import { block_id, StoredWhimBlock } from '../api/protocol';

/**
 * Read/write access to full blocks. Note this should be used as sparingly as possible since you're subscribing to all document updates.
 */
export const useBlocks = () => {
  // console.log('useBlocks hook');
  return useCollection<StoredWhimBlock>('/blocks');
};

/**
 *  Read/write access to a single block.
 */
export const useBlock = (id: block_id) => {
  // console.log('useBlock hook');
  return useDocument<StoredWhimBlock>(`/blocks/${id}`);
};
