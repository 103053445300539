import React from 'react';
import { useDeleteMain } from '../../hooks/useDeleteMain';
import { useBlock } from '../../hooks/useBlock';
import { useMainValue } from '../../hooks/useMain';
import { Button } from '../base/Button';
import { Login } from '../../blocks/login/Login';
import { Register } from '../../blocks/login/Register';
import { Search } from '../../blocks/Search';
import { NoteMain } from '../../blocks/Note';

const MenuBar: React.FC<{ title: string }> = ({ title }) => {
  const deleteMain = useDeleteMain();
  return (
    <div className="py-200 flex flex-1 self-stretch flex-row items-center rounded-md">
      <h1 className="font-semibold text-xl">{title}</h1>
      <div className="flex flex-1"></div>
      <Button variant="negative" onPress={deleteMain}>
        Delete
      </Button>
    </div>
  );
};

export const Main: React.FC = () => {
  // Read-only subscription to id of main atom
  // It changes when a user clicks on the context block, re-rendering the component
  const main_id = useMainValue();
  const { data, update, error, loading } = useBlock(main_id);

  // TODO: add error boundaries here
  if (!data) {
    // eslint-disable-next-line no-console
    console.error(
      'Main note was null or undefined. Using main_id ',
      main_id,
      '. Error: ',
      error
    );
    return null;
  }

  return (
    <div className="flex-1 flex flex-col gap-200 max-w-xl">
      {/* Need to keep this as the title: need to add a title attribute to all kinds of blocks */}
      <MenuBar title={data.title} />
      <hr className="border-t-medium w-2/3 mx-auto" />
      {[data.type].map(type => {
        switch (type) {
          case 'note':
            return (
              <NoteMain
                data={data}
                update={update}
                loading={loading}
                main_id={main_id}
              />
            );
          case 'login':
            return <Login />;
          case 'register':
            return <Register />;
          case 'search':
            return <Search />;
        }
      })}
    </div>
  );
};
