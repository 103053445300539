import React from 'react';
import { Well } from './Well';

interface AlertProps {
  type: 'error' | 'success' | 'neutral';
  heading?: string;
}

export const Alert: React.FC<AlertProps> = ({
  type = 'neutral',
  heading,
  children,
}) => {
  return (
    <Well type={type}>
      {heading && (
        <h1 className="font-semibold text-lg font-heading text-primary">
          {children}
        </h1>
      )}
      <p>{children}</p>
    </Well>
  );
};
