import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { default_main } from '../api/default';
import { Main } from '../api/protocol';

/// Store main id
const main_state = atom<Main>({
  key: 'main',
  default: default_main,
});

/// Read/write access to main state
export const useMainState = () => {
  return useRecoilState(main_state);
};

/// Read access to main state
export const useMainValue = () => {
  return useRecoilValue(main_state);
};
