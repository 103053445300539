import { Index } from '../api/protocol';
import { useContextElState } from './useContext';
import { useMainState } from './useMain';

/// A hook to swap the current main id with the given context Index.
/// Context blocks use this so that when they are clicked, they swap with main.
/// Returns a function to invoke a swap.

export const useContextSwap = (swapWith: Index) => {
  const [side_note_id, set_side_note_id] = useContextElState(swapWith);
  const [main_note_id, set_main_note_id] = useMainState();
  return () => {
    set_side_note_id(main_note_id);
    set_main_note_id(side_note_id);
  };
};
