import React, { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';

interface ButtonProps extends AriaButtonProps<'button'> {
  variant: 'cta' | 'primary' | 'negative';
}

const button_color = {
  cta: 'bg-accent text-background border-background hover:bg-accentShadow focus:shadow-outline-blue transition-colors',
  primary:
    'bg-gray-75 hover:bg-gray-200 text-primary border-primary focus:bg-gray-150 transition-colors',
  negative:
    'bg-red text-background border-background focus:shadow-outline-red transition-colors',
};

export function Button(props: ButtonProps) {
  const ref = useRef() as React.RefObject<HTMLButtonElement>;
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <button
      className={`px-200 py-50 border-medium rounded-medium text-base font-semibold outline-none ${
        button_color[props.variant]
      }`}
      {...buttonProps}
      ref={ref}>
      {children}
    </button>
  );
}
