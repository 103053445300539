import React from 'react';
import { Index } from '../../api/protocol';
import { useContextValue } from '../../hooks/useContext';
import { useContextSwap } from '../../hooks/useContextSwap';
import { useBlock } from '../../hooks/useBlock';
import { NoteContext } from '../../blocks/Note';
import { Search } from '../../blocks/Search';
import { Login } from '../../blocks/login/Login';
import { Register } from '../../blocks/login/Register';

export const Context = ({ side }: { side: 'left' | 'right' }) => {
  const blocks = useContextValue()[side];
  return (
    <div className="flex flex-col gap-600 max-w-xl w-1/5">
      {blocks.map((_, block_el) => (
        <ContextBlock side={side} el={block_el} key={block_el} />
      ))}
    </div>
  );
};

// Unfortunately we have to do it this way so tailwind emits correctly
export const borderColors = {
  celery: 'border-celery',
  chartreuse: 'border-chartreuse',
  magenta: 'border-magenta',
  fuchsia: 'border-fuchsia',
  indigo: 'border-indigo',
  seafoam: 'border-seafoam',
  orange: 'border-orange',
};

export const ContextBlock: React.FC<Index> = ({ side, el }) => {
  const contextSwap = useContextSwap({ side, el });
  const context = useContextValue();
  const block_id = context[side][el == 'last' ? context[side].length - 1 : el];

  const { data } = useBlock(block_id);

  // TODO: Add error boundaries here
  if (!data) {
    // eslint-disable-next-line no-console
    console.error('Context note was null or undefined, id ', block_id);
    return null;
  }

  return (
    <div
      className="flex flex-col gap-100"
      onClick={() => {
        contextSwap();
      }}>
      <div>{data.type == 'note' ? data.title : data.type}</div>
      <div
        className={`p-200 rounded-medium border-large max-h-4800 overflow-y-scroll overflow-x-hidden border-solid ${
          borderColors[data.color]
        } bg-gray-75 cursor-default transition-colors`}>
        {[data.type].map(type => {
          switch (type) {
            case 'note':
              return <NoteContext block={data} block_id={data.id} />;
            // TODO: Pass in the block for each of these
            case 'login':
              return <Login isDisabled />;
            case 'register':
              return <Register isDisabled />;
            case 'search':
              return <Search isDisabled />;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};
