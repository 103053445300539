import { nanoid } from 'nanoid';
import { colors, StoredWhimBlock } from '../api/protocol';
import { useAuth } from './auth/useAuth';
import { useBlocks } from './useBlock';

// Returns a function that creates a new note and returns its id
// TODO: Should be generalized to create any new document, not just a note
// Want it to return a function to create a
export function useNewBlock() {
  const { user } = useAuth();
  const { add } = useBlocks();
  return () => {
    // TODO: don't need to use ids here.
    const id = nanoid();
    const block: StoredWhimBlock = {
      id: id,
      color: colors[Math.floor(Math.random() * colors.length)],
      public: false,
      owner: user?.uid ?? '__anonymous__',
      viewers: ['0'],
      // TODO: make this `undefined`, so it can be visually different from a note that actually uses title "New Note"
      title: 'New Note',
      type: 'note',
      tags: [],
      content: [],
    };
    add(block);
    return id;
  };
}
