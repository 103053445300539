import React from 'react';
import { useAuth } from '../../hooks/auth/useAuth';
import { Alert } from '../../components/base/Alert';
import { Button } from '../../components/base/Button';
import { Form, FormProps } from '../../components/base/Form';
import { TextField } from '../../components/base/Textfield';
import { fuego } from '@nandorojo/swr-firestore';

export const Login: React.FC<FormProps> = props => {
  const { isDisabled } = props;
  const { user, error } = useAuth();
  const [text, setText] = React.useState({
    email: 'place_email',
    password: 'place_password',
  });

  return (
    <Form {...props}>
      {user ? (
        <>
          <Alert type="success">You&apos;re logged in!</Alert>
          <Button
            variant="cta"
            onPress={() => {
              fuego.auth.signOut();
            }}
            isDisabled={isDisabled}>
            Signout
          </Button>
        </>
      ) : (
        <>
          <TextField
            label="Email"
            type="email"
            onChange={(value: string) => setText({ ...text, email: value })}
            isDisabled={isDisabled}
          />
          <TextField
            label="Password"
            type="password"
            onChange={(value: string) => setText({ ...text, password: value })}
            isDisabled={isDisabled}
          />
          <Button
            variant="cta"
            onPress={() => {
              fuego.auth.signInWithEmailAndPassword(text.email, text.password);
              // signIn(text.email, text.password).catch(error => {
              //   console.log(error);
              // });
            }}
            isDisabled={isDisabled}>
            Login
          </Button>
          {error && <Alert type="neutral">{error.message}</Alert>}
        </>
      )}
    </Form>
  );
};
