import { useTextField, AriaTextFieldOptions } from '@react-aria/textfield';
import React, { useRef } from 'react';

export const TextField = (props: AriaTextFieldOptions) => {
  const { label } = props;
  const ref = useRef(null);
  const { labelProps, inputProps } = useTextField(props, ref);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <label
        {...labelProps}
        className="text-sm font-body text-gray-700 outline-none">
        {label}
      </label>
      <input
        {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
        className="px-100 py-50 border-medium outline-none rounded-small border-solid border-gray-500 bg-gray-100 font-body text-gray-900 hover:border-gray-700 hover:disabled:border-gray-500 focus:border-accent focus:disabled:border-gray-50 transition-all"
        ref={ref}
      />
    </div>
  );
};
