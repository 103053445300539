import React, { FormEventHandler } from 'react';

export interface FormProps {
  /** Whether the Form elements are disabled. */
  isDisabled?: boolean;
  /** Whether user input is required on each of the Form elements before Form submission. */
  isRequired?: boolean;
  /** Whether the Form elements can be selected but not changed by the user. */
  isReadOnly?: boolean;
  /**
   * Whether the Form elements should display their "valid" or "invalid" visual styling.
   * @default 'valid'
   */
  validationState?: 'valid' | 'invalid';
  /**
   * Where to send the form-data when the form is submitted.
   */
  action?: string;
  /**
   * The enctype attribute specifies how the form-data should be encoded when submitting it to the server.
   */
  encType?:
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data'
    | 'text/plain';
  /**
   * The form-data can be sent as URL variables (with method="get") or as HTTP post transaction (with method="post").
   */
  method?: 'get' | 'post';
  /**
   * The target attribute specifies a name or a keyword that indicates where to display the response that is received after submitting the form.
   */
  target?: '_blank' | '_self' | '_parent' | '_top';
  /**
   * Fired on form submission.
   */
  onSubmit?: FormEventHandler;
}

export const Form: React.FC<FormProps> = ({ children, ...props }) => {
  return (
    <form {...props} className="flex flex-col items-start gap-200 max-w-4xl">
      {children}
    </form>
  );
};
