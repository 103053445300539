import React from 'react';
import ReactJson from 'react-json-view';
import { Context } from './components/context_editor/Context';
import { Main } from './components/main_editor/MainEditor';
import { useContextValue } from './hooks/useContext';
import { useMainValue } from './hooks/useMain';
import { useNewNoteCommand } from './hooks/useNewNoteCommand';
import { fuego } from './services/firestore';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import { useBlock, useBlocks } from './hooks/useBlock';
import { useAuth } from './hooks/auth/useAuth';

function App() {
  useNewNoteCommand('app');
  return (
    <FuegoProvider fuego={fuego}>
      <div>
        <div className="header"></div>
        <div
          id="app"
          className="flex flex-row items-start gap-800 w-full py-800 justify-evenly">
          <Context side="left" />
          <Main />
          <Context side="right" />
        </div>
        {process.env.NODE_ENV == 'development' && <DebugView />}
        <div className="footer h-800"></div>
      </div>
    </FuegoProvider>
  );
}

const DebugView: React.FC = () => {
  const { data, revalidate } = useBlocks();
  const documents = data ?? {};
  const mainId = useMainValue();
  const { data: mainBlock } = useBlock(mainId);
  const contextState = useContextValue();
  const { user } = useAuth();
  return (
    <div className="bg-gray-900 rounded-lg m-400 mb-800 p-400 flex flex-col gap-100 max-w-2xl">
      <h2 className="text-background font-semibold text-lg mt-200">User</h2>
      <span className="text-background">
        {user ? user.email : '__anonymous__'}
      </span>
      <hr />
      <h2 className="text-background font-semibold text-lg mt-200">Main</h2>
      <span className="text-background">{mainId}</span>
      <ReactJson src={mainBlock ?? {}} theme="monokai" collapsed />
      <hr />
      <h2 className="text-background font-semibold text-lg mt-200">Context</h2>
      <ReactJson src={contextState} theme="monokai" collapsed />
      <hr />
      <div className="flex flex-row gap-400 items-center mt-200">
        <h2 className="text-background font-semibold text-lg ">Blocks</h2>
        <button onClick={revalidate} className="h-600 w-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className=" text-background stroke-current"
            fill="none"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </button>
      </div>

      <ReactJson src={documents} theme="monokai" collapsed />
    </div>
  );
};

export default App;
