import {
  atom,
  useRecoilValue,
  useRecoilState,
  selectorFamily,
  DefaultValue,
} from 'recoil';
import { default_context } from '../api/default';
import produce from 'immer';
import { Context, block_id, Index } from '../api/protocol';

/// Store context ids
const context_state = atom<Context>({
  key: 'context',
  default: default_context,
});

/// This selector family will only give you the id, not the actual document
/// So you pass in an Index ('left' or 'right' and a number) and it gives you the id
/// of the context block at that Index
const context = selectorFamily<block_id, Index>({
  key: 'contextElement',
  get:
    ({ el, side }) =>
    ({ get }) => {
      const context = get(context_state);
      return context[side][el == 'last' ? context[side].length - 1 : el];
    },

  set:
    ({ side, el }) =>
    ({ set }, newValue) => {
      newValue instanceof DefaultValue
        ? set(context_state, default_context)
        : // Uses immer to create a new context
          set(context_state, base => {
            return produce(base, draft => {
              draft[side][el == 'last' ? draft[side].length - 1 : el] =
                newValue;
            });
          });
    },
});

/// Read/write access to full context state
export const useContextState = () => {
  return useRecoilState(context_state);
};

/// Read access to full context state
export const useContextValue = () => {
  return useRecoilValue(context_state);
};

/// Read/write access to individual context el. state
export const useContextElState = (position: Index) => {
  return useRecoilState(context(position));
};

/// Read access to individual context el. state
export const useContextElValue = (position: Index) => {
  return useRecoilValue(context(position));
};
