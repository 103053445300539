import CodeBlock from '@tiptap/extension-code-block';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import { Node } from '@tiptap/core';

export const editor_extensions = [
  // Top node: 'note'
  Node.create({
    name: 'note',
    topNode: true,
    content: 'block+',
  }),
  Paragraph.configure({
    HTMLAttributes: { class: 'text-base text-gray-900 font-body my-100' },
  }),
  Text,
  Heading.configure({
    levels: [1],
    HTMLAttributes: {
      class: 'text-lg font-heading font-semibold mt-200',
    },
  }),
  CodeBlock.configure({
    HTMLAttributes: {
      class:
        'p-200 bg-gray-900 mt-200 font-mono text-sm rounded-large text-gray-150',
    },
  }),
  HorizontalRule.configure({
    HTMLAttributes: { class: 'my-200 border-t-medium border-gray-200' },
  }),
];
