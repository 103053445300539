import { Context, Main } from './protocol';

// Structure of Context dictionary:
/*
<index of context element> : {
  id: <id of note>,
  type: <type of block>,
  etc....
}
*/
// export const default_documents_state: Record<block_id, StoredWhimBlock> = {
//   '0': {
//     id: '0',
//     title: 'Demo page',
//     type: 'note',
//     tags: ['tag_1', 'tag_2'],
//     public: true,
//     owner: 'gXBxt2pZismQowww5ne2FvuPeuek',
//     viewers: ['0'],
//     content: [
//       {
//         type: 'heading',
//         content: [{ type: 'text', text: 'Heading' }],
//       },
//       {
//         type: 'heading',
//         content: [{ type: 'text', text: 'Subheading' }],
//       },
//       { type: 'horizontalRule' },
//       {
//         type: 'paragraph',
//         content: [
//           {
//             type: 'text',
//             text: "Some quick text I'm writing here in a paragrap woohoo!",
//           },
//         ],
//       },
//       {
//         type: 'paragraph',
//         content: [{ type: 'text', text: 'A second paragraph with some code!' }],
//       },
//       {
//         type: 'paragraph',
//         content: [
//           {
//             type: 'text',
//             text: 'A third really long paragraph. We have a number of resources that outline how to set up Spectrum CSS for your project, as well as an introductory tutorial that’s a hands-on way to understand how our implementation works.',
//           },
//         ],
//       },
//       {
//         type: 'paragraph',
//         content: [{ type: 'text', text: ' A code block:' }],
//       },
//       {
//         type: 'codeBlock',
//         attrs: { language: 0 },
//         content: [
//           {
//             type: 'text',
//             text: "import React, { useState } from 'react';\nfunction Example() {\n  // Declare a new state variable, which we'll call \"count\"\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <p>You clicked {count} times</p>\n      <button onClick={() => setCount(count + 1)}>\n        Click me\n      </button>\n    </div>\n  );\n}",
//           },
//         ],
//       },
//     ],
//   },
//   '1': {
//     id: '1',
//     title: 'Demo context page',
//     type: 'note',
//     tags: ['tag_1', 'tag_3'],
//     public: true,
//     owner: '0',
//     viewers: ['0'],
//     content: [
//       {
//         type: 'heading',
//         content: [{ type: 'text', text: 'Demo context page' }],
//       },
//       { type: 'horizontalRule' },
//       {
//         type: 'paragraph',
//         content: [{ type: 'text', text: ' A code block:' }],
//       },
//       // {
//       //   type: 'codeBlock',
//       //   attrs: { language: 0 },
//       //   content: [
//       //     {
//       //       type: 'text',
//       //       text:
//       //         "import React, { useState } from 'react';\nfunction Example() {\n  // Declare a new state variable, which we'll call \"count\"\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <p>You clicked {count} times</p>\n      <button onClick={() => setCount(count + 1)}>\n        Click me\n      </button>\n    </div>\n  );\n}",
//       //     },
//       //   ],
//       // },
//     ],
//   },
//   '2': {
//     id: '2',
//     title: 'Demo right page',
//     type: 'note',
//     public: true,
//     owner: '0',
//     viewers: ['0'],
//     tags: [],
//     content: [
//       {
//         type: 'heading',
//         content: [{ type: 'text', text: 'H1 Heading' }],
//       },
//       {
//         type: 'heading',
//         content: [{ type: 'text', text: 'H2 Heading' }],
//       },
//       {
//         type: 'heading',
//         content: [{ type: 'text', text: 'H3, H4, H5, H6 Headings' }],
//       },
//       { type: 'horizontalRule' },
//       {
//         type: 'paragraph',
//         content: [
//           {
//             type: 'text',
//             text: "Some quick text I'm writing here in a paragraph!",
//           },
//         ],
//       },
//       {
//         type: 'paragraph',
//         content: [{ type: 'text', text: 'A second paragraph with some code!' }],
//       },
//       {
//         type: 'paragraph',
//         content: [
//           {
//             type: 'text',
//             text: 'A third really long paragraph. We have a number of resources that outline how to set up Spectrum CSS for your project, as well as an introductory tutorial that’s a hands-on way to understand how our implementation works.',
//           },
//         ],
//       },
//       {
//         type: 'paragraph',
//         content: [{ type: 'text', text: ' A code block:' }],
//       },
//       {
//         type: 'codeBlock',
//         attrs: { language: 0 },
//         content: [
//           {
//             type: 'text',
//             text: "import React, { useState } from 'react';\nfunction Example() {\n  // Declare a new state variable, which we'll call \"count\"\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <p>You clicked {count} times</p>\n      <button onClick={() => setCount(count + 1)}>\n        Click me\n      </button>\n    </div>\n  );\n}",
//           },
//         ],
//       },
//     ],
//   },
//   '3': {
//     id: '3',
//     type: 'login',
//     title: 'Login',
//     public: true,
//     owner: '0',
//     viewers: ['0'],
//   },
//   '4': {
//     id: '4',
//     type: 'register',
//     title: 'Register',
//     public: true,
//     owner: '0',
//     viewers: ['0'],
//   },
//   '5': {
//     id: '5',
//     type: 'search',
//     title: 'Search',
//     public: true,
//     owner: '0',
//     viewers: ['0'],
//   },
// };

// export const default_main: Main = 'TAUHBdxHl1Dy5QupdlvP';

// export const default_context: Context = {
//   left: ['xpclzOeK0PZHySDmAOGh'],
//   right: ['JixLqqRMw27h9Gss6qSM'],
// };

export const default_main: Main = 'efN5stqoQRfUyNsNe6qN';

export const default_context: Context = {
  left: ['aaQB5UkOXUMD2Foizjlq', 'xpclzOeK0PZHySDmAOGh'],
  right: ['TAUHBdxHl1Dy5QupdlvP', 's76i2CialvCwtUdTxckY'],
};