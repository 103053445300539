import { useEffect, useState } from 'react';
import { fuego } from '../../services/firestore';
import firebase from 'firebase';

export const useAuth = () => {
  // console.log('useAuth hook');
  const [user, setUser] = useState<firebase.User | null>(
    fuego.auth.currentUser
  );
  const [error, setError] = useState<firebase.auth.Error | null>(null);
  // Subscribe to auth changes
  useEffect(() => {
    return fuego.auth.onAuthStateChanged(setUser, setError);
  }, []);
  return {
    user,
    signIn: fuego.auth.signInWithEmailAndPassword,
    error,
    signOut: fuego.auth.signOut,
  };
};
